import { IOng } from 'app/entities/ciblagegeo/ong/ong.model';

export interface IAnimateur {
  id?: number;
  codeAnimateur?: string;
  passwordAnimateur?: string | null;
  password?: string | null;
  nomAnimateur?: string;
  prenomAnimateur?: string;
  telAnimateur?: string;
  emailAnimateur?: string;
  ong?: IOng;
}

export class Animateur implements IAnimateur {
  constructor(
    public id?: number,
    public codeAnimateur?: string,
    public passwordAnimateur?: string | null,
    public password?: string | null,
    public nomAnimateur?: string,
    public prenomAnimateur?: string,
    public telAnimateur?: string,
    public emailAnimateur?: string,
    public ong?: IOng
  ) {}
}

export function getAnimateurIdentifier(animateur: IAnimateur): number | undefined {
  return animateur.id;
}
