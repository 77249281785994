import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { ITypeDecoupage, getTypeDecoupageIdentifier } from '../type-decoupage.model';

export type EntityResponseType = HttpResponse<ITypeDecoupage>;
export type EntityArrayResponseType = HttpResponse<ITypeDecoupage[]>;

@Injectable({ providedIn: 'root' })
export class TypeDecoupageService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/type-decoupages', 'scorepmt');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(typeDecoupage: ITypeDecoupage): Observable<EntityResponseType> {
    return this.http.post<ITypeDecoupage>(this.resourceUrl, typeDecoupage, { observe: 'response' });
  }

  update(typeDecoupage: ITypeDecoupage): Observable<EntityResponseType> {
    return this.http.put<ITypeDecoupage>(`${this.resourceUrl}/${getTypeDecoupageIdentifier(typeDecoupage) as number}`, typeDecoupage, {
      observe: 'response',
    });
  }

  partialUpdate(typeDecoupage: ITypeDecoupage): Observable<EntityResponseType> {
    return this.http.patch<ITypeDecoupage>(`${this.resourceUrl}/${getTypeDecoupageIdentifier(typeDecoupage) as number}`, typeDecoupage, {
      observe: 'response',
    });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ITypeDecoupage>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ITypeDecoupage[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addTypeDecoupageToCollectionIfMissing(
    typeDecoupageCollection: ITypeDecoupage[],
    ...typeDecoupagesToCheck: (ITypeDecoupage | null | undefined)[]
  ): ITypeDecoupage[] {
    const typeDecoupages: ITypeDecoupage[] = typeDecoupagesToCheck.filter(isPresent);
    if (typeDecoupages.length > 0) {
      const typeDecoupageCollectionIdentifiers = typeDecoupageCollection.map(
        typeDecoupageItem => getTypeDecoupageIdentifier(typeDecoupageItem)!
      );
      const typeDecoupagesToAdd = typeDecoupages.filter(typeDecoupageItem => {
        const typeDecoupageIdentifier = getTypeDecoupageIdentifier(typeDecoupageItem);
        if (typeDecoupageIdentifier == null || typeDecoupageCollectionIdentifiers.includes(typeDecoupageIdentifier)) {
          return false;
        }
        typeDecoupageCollectionIdentifiers.push(typeDecoupageIdentifier);
        return true;
      });
      return [...typeDecoupagesToAdd, ...typeDecoupageCollection];
    }
    return typeDecoupageCollection;
  }
}
