<div class="row justify-content-center">
  <div class="col-8">
    <form name="editForm" novalidate role="form">
      <h2 id="jhi-commune-heading" data-cy="CommuneCreateUpdateHeading">
          <span>Importer répartition zones AG</span>
      </h2>


      <jhi-alert-error></jhi-alert-error>

      <jhi-alert></jhi-alert>

      <div class="row">
        <div class="col col-md-5">
          <div class="mb-3">
            <label class="form-control-label" for="field_file">Fichier</label>
            <input id="field_file" class="form-control" type="file" style="width: 50%"
              (change)="choixFichier($event)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-5">
          <div class="mb-3">
            <button type="button" id="cancel-save" class="btn btn-secondary" (click)="previousState()">
              <fa-icon icon="ban"></fa-icon>&nbsp;<span>Quitter</span>
            </button>
            <button type="submit" class="btn btn-info" (click)="envoyerFichier()" [disabled]="isIntegring || noFile">
              <fa-icon icon="sync" [spin]="isIntegring"></fa-icon>
              <span>Importer...</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>