import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { ICriterePauvrete, getCriterePauvreteIdentifier } from '../critere-pauvrete.model';
import { IAgCpDto } from 'app/entities/ciblagecom/ciblage-com.model';

export type EntityResponseType = HttpResponse<ICriterePauvrete>;
export type EntityArrayResponseType = HttpResponse<ICriterePauvrete[]>;

@Injectable({ providedIn: 'root' })
export class CriterePauvreteService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/critere-pauvretes', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(criterePauvrete: ICriterePauvrete): Observable<EntityResponseType> {
    return this.http.post<ICriterePauvrete>(this.resourceUrl, criterePauvrete, { observe: 'response' });
  }

  update(criterePauvrete: ICriterePauvrete): Observable<EntityResponseType> {
    return this.http.put<ICriterePauvrete>(
      `${this.resourceUrl}/${getCriterePauvreteIdentifier(criterePauvrete) as number}`,
      criterePauvrete,
      { observe: 'response' }
    );
  }

  partialUpdate(criterePauvrete: ICriterePauvrete): Observable<EntityResponseType> {
    return this.http.patch<ICriterePauvrete>(
      `${this.resourceUrl}/${getCriterePauvreteIdentifier(criterePauvrete) as number}`,
      criterePauvrete,
      { observe: 'response' }
    );
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICriterePauvrete>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICriterePauvrete[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  queryByAg(idAg:number): Observable<HttpResponse<IAgCpDto>> {
    const url = this.resourceUrl + '/par-ag';
    return this.http
      .get<IAgCpDto>(url, { params: {idAg: `${idAg}`}, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addCriterePauvreteToCollectionIfMissing(
    criterePauvreteCollection: ICriterePauvrete[],
    ...criterePauvretesToCheck: (ICriterePauvrete | null | undefined)[]
  ): ICriterePauvrete[] {
    const criterePauvretes: ICriterePauvrete[] = criterePauvretesToCheck.filter(isPresent);
    if (criterePauvretes.length > 0) {
      const criterePauvreteCollectionIdentifiers = criterePauvreteCollection.map(
        criterePauvreteItem => getCriterePauvreteIdentifier(criterePauvreteItem)!
      );
      const criterePauvretesToAdd = criterePauvretes.filter(criterePauvreteItem => {
        const criterePauvreteIdentifier = getCriterePauvreteIdentifier(criterePauvreteItem);
        if (criterePauvreteIdentifier == null || criterePauvreteCollectionIdentifiers.includes(criterePauvreteIdentifier)) {
          return false;
        }
        criterePauvreteCollectionIdentifiers.push(criterePauvreteIdentifier);
        return true;
      });
      return [...criterePauvretesToAdd, ...criterePauvreteCollection];
    }
    return criterePauvreteCollection;
  }
}
