import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { INaturePieceIdentite, getNaturePieceIdentiteIdentifier } from '../nature-piece-identite.model';

export type EntityResponseType = HttpResponse<INaturePieceIdentite>;
export type EntityArrayResponseType = HttpResponse<INaturePieceIdentite[]>;

@Injectable({ providedIn: 'root' })
export class NaturePieceIdentiteService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/nature-piece-identites', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(naturePieceIdentite: INaturePieceIdentite): Observable<EntityResponseType> {
    return this.http.post<INaturePieceIdentite>(this.resourceUrl, naturePieceIdentite, { observe: 'response' });
  }

  update(naturePieceIdentite: INaturePieceIdentite): Observable<EntityResponseType> {
    return this.http.put<INaturePieceIdentite>(
      `${this.resourceUrl}/${getNaturePieceIdentiteIdentifier(naturePieceIdentite) as number}`,
      naturePieceIdentite,
      { observe: 'response' }
    );
  }

  partialUpdate(naturePieceIdentite: INaturePieceIdentite): Observable<EntityResponseType> {
    return this.http.patch<INaturePieceIdentite>(
      `${this.resourceUrl}/${getNaturePieceIdentiteIdentifier(naturePieceIdentite) as number}`,
      naturePieceIdentite,
      { observe: 'response' }
    );
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<INaturePieceIdentite>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<INaturePieceIdentite[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addNaturePieceIdentiteToCollectionIfMissing(
    naturePieceIdentiteCollection: INaturePieceIdentite[],
    ...naturePieceIdentitesToCheck: (INaturePieceIdentite | null | undefined)[]
  ): INaturePieceIdentite[] {
    const naturePieceIdentites: INaturePieceIdentite[] = naturePieceIdentitesToCheck.filter(isPresent);
    if (naturePieceIdentites.length > 0) {
      const naturePieceIdentiteCollectionIdentifiers = naturePieceIdentiteCollection.map(
        naturePieceIdentiteItem => getNaturePieceIdentiteIdentifier(naturePieceIdentiteItem)!
      );
      const naturePieceIdentitesToAdd = naturePieceIdentites.filter(naturePieceIdentiteItem => {
        const naturePieceIdentiteIdentifier = getNaturePieceIdentiteIdentifier(naturePieceIdentiteItem);
        if (naturePieceIdentiteIdentifier == null || naturePieceIdentiteCollectionIdentifiers.includes(naturePieceIdentiteIdentifier)) {
          return false;
        }
        naturePieceIdentiteCollectionIdentifiers.push(naturePieceIdentiteIdentifier);
        return true;
      });
      return [...naturePieceIdentitesToAdd, ...naturePieceIdentiteCollection];
    }
    return naturePieceIdentiteCollection;
  }
}
