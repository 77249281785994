import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IRoleAnimateur, getRoleAnimateurIdentifier } from '../role-animateur.model';

export type EntityResponseType = HttpResponse<IRoleAnimateur>;
export type EntityArrayResponseType = HttpResponse<IRoleAnimateur[]>;

@Injectable({ providedIn: 'root' })
export class RoleAnimateurService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/role-animateurs', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(roleAnimateur: IRoleAnimateur): Observable<EntityResponseType> {
    return this.http.post<IRoleAnimateur>(this.resourceUrl, roleAnimateur, { observe: 'response' });
  }

  update(roleAnimateur: IRoleAnimateur): Observable<EntityResponseType> {
    return this.http.put<IRoleAnimateur>(`${this.resourceUrl}/${getRoleAnimateurIdentifier(roleAnimateur) as number}`, roleAnimateur, {
      observe: 'response',
    });
  }

  partialUpdate(roleAnimateur: IRoleAnimateur): Observable<EntityResponseType> {
    return this.http.patch<IRoleAnimateur>(`${this.resourceUrl}/${getRoleAnimateurIdentifier(roleAnimateur) as number}`, roleAnimateur, {
      observe: 'response',
    });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IRoleAnimateur>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IRoleAnimateur[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addRoleAnimateurToCollectionIfMissing(
    roleAnimateurCollection: IRoleAnimateur[],
    ...roleAnimateursToCheck: (IRoleAnimateur | null | undefined)[]
  ): IRoleAnimateur[] {
    const roleAnimateurs: IRoleAnimateur[] = roleAnimateursToCheck.filter(isPresent);
    if (roleAnimateurs.length > 0) {
      const roleAnimateurCollectionIdentifiers = roleAnimateurCollection.map(
        roleAnimateurItem => getRoleAnimateurIdentifier(roleAnimateurItem)!
      );
      const roleAnimateursToAdd = roleAnimateurs.filter(roleAnimateurItem => {
        const roleAnimateurIdentifier = getRoleAnimateurIdentifier(roleAnimateurItem);
        if (roleAnimateurIdentifier == null || roleAnimateurCollectionIdentifiers.includes(roleAnimateurIdentifier)) {
          return false;
        }
        roleAnimateurCollectionIdentifiers.push(roleAnimateurIdentifier);
        return true;
      });
      return [...roleAnimateursToAdd, ...roleAnimateurCollection];
    }
    return roleAnimateurCollection;
  }
}
