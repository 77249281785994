import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IFormulePmt, getFormulePmtIdentifier } from '../formule-pmt.model';

export type EntityResponseType = HttpResponse<IFormulePmt>;
export type EntityArrayResponseType = HttpResponse<IFormulePmt[]>;

@Injectable({ providedIn: 'root' })
export class FormulePmtService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/formule-pmts', 'scorepmt');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(formulePmt: IFormulePmt): Observable<EntityResponseType> {
    return this.http.post<IFormulePmt>(this.resourceUrl, formulePmt, { observe: 'response' });
  }

  update(formulePmt: IFormulePmt): Observable<EntityResponseType> {
    return this.http.put<IFormulePmt>(`${this.resourceUrl}/${getFormulePmtIdentifier(formulePmt) as number}`, formulePmt, {
      observe: 'response',
    });
  }

  partialUpdate(formulePmt: IFormulePmt): Observable<EntityResponseType> {
    return this.http.patch<IFormulePmt>(`${this.resourceUrl}/${getFormulePmtIdentifier(formulePmt) as number}`, formulePmt, {
      observe: 'response',
    });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IFormulePmt>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IFormulePmt[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  queryByModele(idModele: number): Observable<EntityArrayResponseType> {
    return this.http.get<IFormulePmt[]>(this.resourceUrl, { params: {idModele : `${idModele}`}, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addFormulePmtToCollectionIfMissing(
    formulePmtCollection: IFormulePmt[],
    ...formulePmtsToCheck: (IFormulePmt | null | undefined)[]
  ): IFormulePmt[] {
    const formulePmts: IFormulePmt[] = formulePmtsToCheck.filter(isPresent);
    if (formulePmts.length > 0) {
      const formulePmtCollectionIdentifiers = formulePmtCollection.map(formulePmtItem => getFormulePmtIdentifier(formulePmtItem)!);
      const formulePmtsToAdd = formulePmts.filter(formulePmtItem => {
        const formulePmtIdentifier = getFormulePmtIdentifier(formulePmtItem);
        if (formulePmtIdentifier == null || formulePmtCollectionIdentifiers.includes(formulePmtIdentifier)) {
          return false;
        }
        formulePmtCollectionIdentifiers.push(formulePmtIdentifier);
        return true;
      });
      return [...formulePmtsToAdd, ...formulePmtCollection];
    }
    return formulePmtCollection;
  }

  envoyerFichiersCoefficients(fichierAEnvoyer: File | null, login: string, idFormule: number): Observable<HttpResponse<void>> {
    const url = this.applicationConfigService.getEndpointFor('api/import-coefficients-formule', 'scorepmt');
    const formData = new FormData();

    formData.append('file', fichierAEnvoyer!, fichierAEnvoyer!.name);
    return this.http.post<void>(url, formData, {
      params: { login: `${login}`, idFormule: `${idFormule}` },
      observe: 'response',
    });
  }
}
