import { IProvince } from 'app/entities/ciblagegeo/province/province.model';

export interface ICommune {
  id?: number;
  codeCommune?: string;
  nomCommune?: string;
  nbMenageTotal?: number | null;
  populationTotale?: number | null;
  nbMenagePauvre?: number | null;
  province?: IProvince;
}

export class Commune implements ICommune {
  constructor(
    public id?: number,
    public codeCommune?: string,
    public nomCommune?: string,
    public nbMenageTotal?: number | null,
    public populationTotale?: number | null,
    public nbMenagePauvre?: number | null,
    public province?: IProvince
  ) {}
}

export function getCommuneIdentifier(commune: ICommune): number | undefined {
  return commune.id;
}
