import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IModelePmt, getModelePmtIdentifier } from '../modele-pmt.model';

export type EntityResponseType = HttpResponse<IModelePmt>;
export type EntityArrayResponseType = HttpResponse<IModelePmt[]>;

@Injectable({ providedIn: 'root' })
export class ModelePmtService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/modele-pmts', 'scorepmt');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(modelePmt: IModelePmt): Observable<EntityResponseType> {
    return this.http.post<IModelePmt>(this.resourceUrl, modelePmt, { observe: 'response' });
  }

  calculPmt(): Observable<HttpResponse<void>> {
    const resourceUrl = this.applicationConfigService.getEndpointFor('api/calcul-pmts', 'scorepmt');
    return this.http.post<void>(resourceUrl, undefined, { observe: 'response' });
  }

  update(modelePmt: IModelePmt): Observable<EntityResponseType> {
    return this.http.put<IModelePmt>(`${this.resourceUrl}/${getModelePmtIdentifier(modelePmt) as number}`, modelePmt, {
      observe: 'response',
    });
  }

  partialUpdate(modelePmt: IModelePmt): Observable<EntityResponseType> {
    return this.http.patch<IModelePmt>(`${this.resourceUrl}/${getModelePmtIdentifier(modelePmt) as number}`, modelePmt, {
      observe: 'response',
    });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IModelePmt>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IModelePmt[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addModelePmtToCollectionIfMissing(
    modelePmtCollection: IModelePmt[],
    ...modelePmtsToCheck: (IModelePmt | null | undefined)[]
  ): IModelePmt[] {
    const modelePmts: IModelePmt[] = modelePmtsToCheck.filter(isPresent);
    if (modelePmts.length > 0) {
      const modelePmtCollectionIdentifiers = modelePmtCollection.map(modelePmtItem => getModelePmtIdentifier(modelePmtItem)!);
      const modelePmtsToAdd = modelePmts.filter(modelePmtItem => {
        const modelePmtIdentifier = getModelePmtIdentifier(modelePmtItem);
        if (modelePmtIdentifier == null || modelePmtCollectionIdentifiers.includes(modelePmtIdentifier)) {
          return false;
        }
        modelePmtCollectionIdentifiers.push(modelePmtIdentifier);
        return true;
      });
      return [...modelePmtsToAdd, ...modelePmtCollection];
    }
    return modelePmtCollection;
  }
}
