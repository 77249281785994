import { ILocalite } from 'app/entities/ciblagegeo/localite/localite.model';

export interface IZoneDenombrement {
  id?: number;
  codeZone?: string;
  numeroZd?: number;
  quota?: number;
  estZd?: boolean;
  localite?: ILocalite;
  new?: boolean; // précise si la ZD est nouvelle (true) ou en édition (flase)
}

export class ZoneDenombrement implements IZoneDenombrement {
  constructor(
    public id?: number,
    public codeZone?: string,
    public numeroZd?: number,
    public quota?: number,
    public estZd?: boolean,
    public localite?: ILocalite
  ) {
    this.estZd = this.estZd ?? false;
  }
}

export function getZoneDenombrementIdentifier(zoneDenombrement: IZoneDenombrement): number | undefined {
  return zoneDenombrement.id;
}

export interface IZdQuotaDto {
  id?: number
  codeZone?: string;
  numeroZd?: number
  nomLocalite?: string;
  commune: string;
  province: string;
  quota?: number | null;
  nbMenagesPreidentifies: number | null;
}
