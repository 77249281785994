import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IEnqueteur } from '../enqueteur.model';
import { EnqueteurService } from '../service/enqueteur.service';
import { AccountService } from 'app/core/auth/account.service';


@Component({
  templateUrl: './enqueteur-regenpass-dialog.component.html',
})
export class EnqueteurRegenPassDialogComponent {
  enqueteur?: IEnqueteur;
  password?: string;

  constructor(protected enqueteurService: EnqueteurService, 
    protected accountService: AccountService,
    public activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirm(): void {
    this.accountService.identity().subscribe(account => {
      this.enqueteur!.passwordRegenPar = account;
    });
    this.enqueteurService.regenPass(this.enqueteur!).subscribe(res => {
      this.password = res.body!;
    });
  }
}
