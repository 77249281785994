import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RegenPassUserDialogComponent } from './regenpass-user-dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [RegenPassUserDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalModule { }
