import { IAssembleeGenerale } from 'app/entities/ciblagegeo/assemblee-generale/assemblee-generale.model';

export interface ICriterePauvrete {
  id?: number;
  numeroImportance?: number;
  codeCritere?: string | null;
  libelleCritere?: string | null;
  assembleeGenerale?: IAssembleeGenerale;
}

export class CriterePauvrete implements ICriterePauvrete {
  constructor(
    public id?: number,
    public numeroImportance?: number,
    public codeCritere?: string | null,
    public libelleCritere?: string | null,
    public assembleeGenerale?: IAssembleeGenerale
  ) {}
}

export function getCriterePauvreteIdentifier(criterePauvrete: ICriterePauvrete): number | undefined {
  return criterePauvrete.id;
}
