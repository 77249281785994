<form name="regenForm">
  <div class="modal-header">
    <h4 class="modal-title" data-cy="animateurDeleteDialogHeading">Regénération mot de passe</h4>

    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <jhi-alert-error></jhi-alert-error>

    <jhi-alert></jhi-alert>
    
    <p id="jhi-delete-animateur-heading">Regénérer le mot de passe de [{{ login }} : {{ nomUser }} {{ prenomUser }}] ; confirmez-vous ?</p>

    <div class="btn-primary" *ngIf="password" style="padding: 3px;">
      <span>Mot de passe : {{ password }}</span>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" [disabled]="isLoading" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <fa-icon icon="ban"></fa-icon>&nbsp;<span>Quitter</span>
    </button>

    <button id="jhi-confirm-delete-animateur" [disabled]="isLoading" (click)="confirm()" data-cy="entityConfirmDeleteButton" type="button" class="btn btn-primary">
      <span>Regénérer</span>
      <span class="spinner-grow spinner-grow-sm" *ngIf="isLoading"></span>
    </button>
  </div>
</form>
