import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocaliteService } from '../../localite/service/localite.service';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';

@Component({
  selector: 'jhi-import-zone-ag',
  templateUrl: './import-repartition-zone-ag.component.html',
})
export class ImportRepartitionZoneAgComponent {
  isIntegring = false;
  noFile = false;

  fichierAEnvoyer: File | null = null;

  constructor(
    protected modalService: NgbModal,
    protected accounService: AccountService,
    protected localiteService: LocaliteService,
  ) { }

  previousState(): void {
    window.history.back();
  }

  choixFichier(event: any): void {
    if (event.target.files.length > 0) {
      this.fichierAEnvoyer = event.target.files[0];
      this.noFile = false;
    } else {
      this.noFile = true;
    }
  }

  envoyerFichier(): void {
    this.isIntegring = true;
    this.accounService.identity().subscribe(account => {
      this.localiteService.envoyerFichierRepartitionZonesAG(this.fichierAEnvoyer, account!.idOng!).pipe(finalize(() => this.onSaveFinalize())).subscribe(
        () => this.onSaveSuccess(),
        () => this.onSaveError(),
      );
    });
  }

  protected onSaveSuccess(): void {
    this.isIntegring = false
    // onSave
  }

  protected onSaveError(): void {
    this.isIntegring = false;
  }

  protected onSaveFinalize(): void {
    this.isIntegring = false
  }
}
