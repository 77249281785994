<form *ngIf="enqueteur" name="deleteForm" (ngSubmit)="confirm()">
  <div class="modal-header">
    <h4 class="modal-title" data-cy="enqueteurDeleteDialogHeading">Regénération mot de passe</h4>

    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
  </div>

  <div class="modal-body">
    <jhi-alert-error></jhi-alert-error>

    <p id="jhi-delete-enqueteur-heading">Regénéer le mot de passe [{{ enqueteur.nomEnqueteur }} {{ enqueteur.prenomEnqueteur }}] ; confirmez-vous ?</p>

    <div class="btn-primary" *ngIf="password">
      <span>Mot de passe : {{ password }}</span>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <fa-icon icon="ban"></fa-icon>&nbsp;<span>Quitter</span>
    </button>

    <button id="jhi-confirm-delete-enqueteur" (click)="confirm()" data-cy="entityConfirmDeleteButton" type="button" class="btn btn-primary">
      <span>Regénérer</span>
    </button>
  </div>
</form>
