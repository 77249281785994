import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IZoneDenombrement, getZoneDenombrementIdentifier } from '../zone-denombrement.model';

export type EntityResponseType = HttpResponse<IZoneDenombrement>;
export type EntityArrayResponseType = HttpResponse<IZoneDenombrement[]>;

@Injectable({ providedIn: 'root' })
export class ZoneDenombrementService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/zone-denombrements', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(zoneDenombrement: IZoneDenombrement): Observable<EntityResponseType> {
    return this.http.post<IZoneDenombrement>(this.resourceUrl, zoneDenombrement, { observe: 'response' });
  }

  update(zoneDenombrement: IZoneDenombrement): Observable<EntityResponseType> {
    return this.http.put<IZoneDenombrement>(
      `${this.resourceUrl}/${getZoneDenombrementIdentifier(zoneDenombrement) as number}`,
      zoneDenombrement,
      { observe: 'response' }
    );
  }

  partialUpdate(zoneDenombrement: IZoneDenombrement): Observable<EntityResponseType> {
    return this.http.patch<IZoneDenombrement>(
      `${this.resourceUrl}/${getZoneDenombrementIdentifier(zoneDenombrement) as number}`,
      zoneDenombrement,
      { observe: 'response' }
    );
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IZoneDenombrement>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IZoneDenombrement[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addZoneDenombrementToCollectionIfMissing(
    zoneDenombrementCollection: IZoneDenombrement[],
    ...zoneDenombrementsToCheck: (IZoneDenombrement | null | undefined)[]
  ): IZoneDenombrement[] {
    const zoneDenombrements: IZoneDenombrement[] = zoneDenombrementsToCheck.filter(isPresent);
    if (zoneDenombrements.length > 0) {
      const zoneDenombrementCollectionIdentifiers = zoneDenombrementCollection.map(
        zoneDenombrementItem => getZoneDenombrementIdentifier(zoneDenombrementItem)!
      );
      const zoneDenombrementsToAdd = zoneDenombrements.filter(zoneDenombrementItem => {
        const zoneDenombrementIdentifier = getZoneDenombrementIdentifier(zoneDenombrementItem);
        if (zoneDenombrementIdentifier == null || zoneDenombrementCollectionIdentifiers.includes(zoneDenombrementIdentifier)) {
          return false;
        }
        zoneDenombrementCollectionIdentifiers.push(zoneDenombrementIdentifier);
        return true;
      });
      return [...zoneDenombrementsToAdd, ...zoneDenombrementCollection];
    }
    return zoneDenombrementCollection;
  }
}
