export interface IRoleAnimateur {
  id?: number;
  code?: string;
  libelle?: string;
  multiple?: boolean;
}

export class RoleAnimateur implements IRoleAnimateur {
  constructor(public id?: number, public code?: string, public libelle?: string, public multiple?: boolean) {
    this.multiple = this.multiple ?? false;
  }
}

export function getRoleAnimateurIdentifier(roleAnimateur: IRoleAnimateur): number | undefined {
  return roleAnimateur.id;
}
