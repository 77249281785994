import { IModelePmt } from 'app/entities/scorepmt/modele-pmt/modele-pmt.model';

export interface IFormulePmt {
  id?: number;
  libelle?: string;
  description?: string | null;
  valeurDeDecoupage?: string;
  modelePmt?: IModelePmt;
  selected?: boolean;
}

export class FormulePmt implements IFormulePmt {
  constructor(
    public id?: number,
    public libelle?: string,
    public description?: string | null,
    public valeurDeDecoupage?: string,
    public modelePmt?: IModelePmt
  ) {}
}

export function getFormulePmtIdentifier(formulePmt: IFormulePmt): number | undefined {
  return formulePmt.id;
}
