import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { ICommune, getCommuneIdentifier } from '../commune.model';
import { ISuperviseurOngCommune as ISuperviseurOngCommuneDto } from '../../superviseur-ong/superviseur-ong.model';
import { ISuperviseurAgenceCommune } from 'app/entities/ciblagecom/superviseur-agence/superviseur-agence.model';

export type EntityResponseType = HttpResponse<ICommune>;
export type EntityArrayResponseType = HttpResponse<ICommune[]>;

@Injectable({ providedIn: 'root' })
export class CommuneService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/communes', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(commune: ICommune): Observable<EntityResponseType> {
    return this.http.post<ICommune>(this.resourceUrl, commune, { observe: 'response' });
  }

  affecterCommunesASuperviseurOng(superviseurOngCommune: ISuperviseurOngCommuneDto): Observable<HttpResponse<ISuperviseurOngCommuneDto>> {
    const url = this.resourceUrl + "/affecter-communes-superviseur-ong";
    return this.http.post<ISuperviseurOngCommuneDto>(url, superviseurOngCommune, { observe: 'response' });
  }

  affecterCommunesASuperviseurAgence(superviseurAgenceCommune: ISuperviseurAgenceCommune): Observable<HttpResponse<ISuperviseurAgenceCommune>> {
    const url = this.resourceUrl + "/affecter-communes-superviseur-agence";
    return this.http.post<ISuperviseurAgenceCommune>(url, superviseurAgenceCommune, { observe: 'response' });
  }

  update(commune: ICommune): Observable<EntityResponseType> {
    return this.http.put<ICommune>(`${this.resourceUrl}/${getCommuneIdentifier(commune) as number}`, commune, { observe: 'response' });
  }

  partialUpdate(commune: ICommune): Observable<EntityResponseType> {
    return this.http.patch<ICommune>(`${this.resourceUrl}/${getCommuneIdentifier(commune) as number}`, commune, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ICommune>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ICommune[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  queryByProvince(idProvince: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/par-province";
    return this.http.get<ICommune[]>(url, { params: {idProvince: `${idProvince}`}, observe: 'response' });
  }

  queryNonAffecteesOngASuperviseurByProvince(idProvince: number, idSuperviseur: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/non-affectees-superviseur-ong-par-province";
    return this.http.get<ICommune[]>(url, { params: {idProvince: `${idProvince}`, idSuperviseur: `${idSuperviseur}`}, observe: 'response' });
  }

  queryNonAffecteesByProvince(idProvince: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/non-affectees-superviseur-par-province";
    return this.http.get<ICommune[]>(url, { params: {idProvince: `${idProvince}`}, observe: 'response' });
  }

  

  queryAffecteesBySuperviseurOng(idSuperviseur: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/affectees-par-superviseur-ong";
    return this.http.get<ICommune[]>(url, { params: {idSuperviseur: `${idSuperviseur}`}, observe: 'response' });
  }

  queryNonAffecteesAgenceASuperviseurByProvince(idProvince: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/non-affectees-superviseur-agence-par-province";
    return this.http.get<ICommune[]>(url, { params: {idProvince: `${idProvince}`}, observe: 'response' });
  }

  queryAffecteesBySuperviseurAgence(idSuperviseur: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/affectees-par-superviseur-agence";
    return this.http.get<ICommune[]>(url, { params: {idSuperviseur: `${idSuperviseur}`}, observe: 'response' });
  }

  queryAffecteesByControleur(idControleur: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/affectees-par-controleur";
    return this.http.get<ICommune[]>(url, { params: {idControleur: `${idControleur}`}, observe: 'response' });
  }

  queryNonAffecteesAgenceControleurByProvince(idProvince: number): Observable<EntityArrayResponseType> {
    const url = this.resourceUrl + "/non-affectees-controleur-par-province";
    return this.http.get<ICommune[]>(url, { params: {idProvince: `${idProvince}`}, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addCommuneToCollectionIfMissing(communeCollection: ICommune[], ...communesToCheck: (ICommune | null | undefined)[]): ICommune[] {
    const communes: ICommune[] = communesToCheck.filter(isPresent);
    if (communes.length > 0) {
      const communeCollectionIdentifiers = communeCollection.map(communeItem => getCommuneIdentifier(communeItem)!);
      const communesToAdd = communes.filter(communeItem => {
        const communeIdentifier = getCommuneIdentifier(communeItem);
        if (communeIdentifier == null || communeCollectionIdentifiers.includes(communeIdentifier)) {
          return false;
        }
        communeCollectionIdentifiers.push(communeIdentifier);
        return true;
      });
      return [...communesToAdd, ...communeCollection];
    }
    return communeCollection;
  }
}
