export interface IRegion {
  id?: number;
  codeNationalRegion?: string;
  codeRegion?: string;
  nomRegion?: string;
}

export class Region implements IRegion {
  constructor(public id?: number, public codeNationalRegion?: string, public codeRegion?: string, public nomRegion?: string) {}
}

export function getRegionIdentifier(region: IRegion): number | undefined {
  return region.id;
}
