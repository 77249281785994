export interface ITypeDecoupage {
  id?: number;
  libelle?: string;
  caracteristiqueDeDecoupage?: string;
  unique?: boolean;
}

export class TypeDecoupage implements ITypeDecoupage {
  constructor(public id?: number, public libelle?: string, public caracteristiqueDeDecoupage?: string, public unique?: boolean) {
    this.unique = this.unique ?? false;
  }
}

export function getTypeDecoupageIdentifier(typeDecoupage: ITypeDecoupage): number | undefined {
  return typeDecoupage.id;
}
