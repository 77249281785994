import * as dayjs from 'dayjs';

export interface ISessionDepartementale {
  id?: number;
  reference?: string;
  dateSession?: dayjs.Dayjs;
  dateDebutSession?: dayjs.Dayjs;
  dateFinSession?: dayjs.Dayjs;
  heureDebutSession?: Date;
  heureFinSession?: Date;
  observation?: string | null;
  lieuSession?: string | null;
  nbMembrePresent?: number | null;
}

export class SessionDepartementale implements ISessionDepartementale {
  constructor(
    public id?: number,
    public reference?: string,
    public dateSession?: dayjs.Dayjs,
    public observation?: string | null,
    public lieuSession?: string | null,
    public nbMembrePresent?: number | null
  ) {}
}

export function getSessionDepartementaleIdentifier(sessionDepartementale: ISessionDepartementale): number | undefined {
  return sessionDepartementale.id;
}
