import * as dayjs from 'dayjs';
import { IAssembleeGenerale } from 'app/entities/ciblagegeo/assemblee-generale/assemblee-generale.model';
import { INaturePieceIdentite } from 'app/entities/ciblagegeo/nature-piece-identite/nature-piece-identite.model';

export interface IMenagePreidentifie {
  id?: number;
  numeroCoupon?: string | null;
  nomChefMenage?: string | null;
  prenomChefMenage?: string | null;
  surnomChefMenage?: string | null;
  nomRepresentant?: string | null;
  prenomRepresentant?: string | null;
  surnomRepresentant?: string | null;
  identifiantUnique?: string | null;
  numeroPieceIdentite?: string | null;
  dateEtablissmtPiece?: dayjs.Dayjs | null;
  lieuEtablissmtPiece?: string | null;
  statutRepresentant?: string | null;
  quatierMenage?: string | null;
  tailleMenage?: number | null;
  telRepresentant?: string | null;
  telChefMenage?: string | null;
  languesParles?: string | null;
  assembleeGenerale?: IAssembleeGenerale;
  naturePieceIdentite?: INaturePieceIdentite;
}

export class MenagePreidentifie implements IMenagePreidentifie {
  constructor(
    public id?: number,
    public numeroCoupon?: string | null,
    public nomRepresentant?: string | null,
    public prenomRepresentant?: string | null,
    public surnomRepresentant?: string | null,
    public identifiantUnique?: string | null,
    public numeroPieceIdentite?: string | null,
    public dateEtablissmtPiece?: dayjs.Dayjs | null,
    public lieuEtablissmtPiece?: string | null,
    public statutRepresentant?: string | null,
    public quatierMenage?: string | null,
    public tailleMenage?: number | null,
    public telRepresentant?: string | null,
    public languesParles?: string | null,
    public assembleeGenerale?: IAssembleeGenerale,
    public naturePieceIdentite?: INaturePieceIdentite
  ) {}
}

export class IAgMpDto{
  nomLocalite?: string | null;
  nomCommune?: string | null;
  nomProvince?: string | null;
  nomRegion?: string | null;
  nbMpTotal?: number | null;
  listeMp?: IMenagePreidentifie[] | null;
}

export function getMenagePreidentifieIdentifier(menagePreidentifie: IMenagePreidentifie): number | undefined {
  return menagePreidentifie.id;
}
