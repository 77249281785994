import * as dayjs from 'dayjs';
import { IZoneDenombrement } from 'app/entities/ciblagegeo/zone-denombrement/zone-denombrement.model';

export interface IAssembleeGenerale {
  id?: number;
  referencePv?: string | null;
  lieu?: string | null;
  dateDebut?: dayjs.Dayjs | null;
  dateFin?: dayjs.Dayjs | null;
  nbMenagesPresents?: number | null;
  tauxParticipation?: number | null;
  observation?: string | null;
  zoneDenombrement?: IZoneDenombrement;
}

export class AssembleeGenerale implements IAssembleeGenerale {
  constructor(
    public id?: number,
    public referencePv?: string | null,
    public lieu?: string | null,
    public dateDebut?: dayjs.Dayjs | null,
    public dateFin?: dayjs.Dayjs | null,
    public nbMenagesPresents?: number | null,
    public tauxParticipation?: number | null,
    public observation?: string | null,
    public zoneDenombrement?: IZoneDenombrement
  ) {}
}

export function getAssembleeGeneraleIdentifier(assembleeGenerale: IAssembleeGenerale): number | undefined {
  return assembleeGenerale.id;
}
