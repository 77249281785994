export interface INaturePieceIdentite {
  id?: number;
  code?: string | null;
  libelle?: string | null;
}

export class NaturePieceIdentite implements INaturePieceIdentite {
  constructor(public id?: number, public code?: string | null, public libelle?: string | null) {}
}

export function getNaturePieceIdentiteIdentifier(naturePieceIdentite: INaturePieceIdentite): number | undefined {
  return naturePieceIdentite.id;
}
