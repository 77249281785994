import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { ISuperviseurOng } from '../superviseur-ong.model';

export type EntityResponseType = HttpResponse<ISuperviseurOng>;
export type EntityArrayResponseType = HttpResponse<ISuperviseurOng[]>;

@Injectable({ providedIn: 'root' })
export class SuperviseurOngService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/superviseur-ongs', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) { }

  create(superviseurOng: ISuperviseurOng): Observable<EntityResponseType> {
    return this.http.post<ISuperviseurOng>(this.resourceUrl, superviseurOng, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<ISuperviseurOng>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByUser(login: string): Observable<EntityResponseType> {
    const url = this.applicationConfigService.getEndpointFor('api/superviseur-ong-user', 'ciblagegeo');
    return this.http.get<ISuperviseurOng>(url, { params: { login: `${login}` }, observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ISuperviseurOng[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  queryByOng(idOng: number): Observable<EntityArrayResponseType> {
    const url = this.applicationConfigService.getEndpointFor('api/superviseur-ongs/par-ong', 'ciblagegeo');
    return this.http.get<ISuperviseurOng[]>(url, { params: { idOng: `${idOng}` }, observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  regenPass(superviseurOng: ISuperviseurOng): Observable<HttpResponse<ISuperviseurOng>> {
    const url = this.applicationConfigService.getEndpointFor('api/superviseur-ongs-regenerer-passwd', 'ciblagegeo')
    return this.http.post<ISuperviseurOng>(url, superviseurOng, { observe: 'response' });
  }

}
