import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IAnimateur, getAnimateurIdentifier } from '../animateur.model';

export type EntityResponseType = HttpResponse<IAnimateur>;
export type EntityArrayResponseType = HttpResponse<IAnimateur[]>;

@Injectable({ providedIn: 'root' })
export class AnimateurService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/animateurs', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(animateur: IAnimateur): Observable<EntityResponseType> {
    return this.http.post<IAnimateur>(this.resourceUrl, animateur, { observe: 'response' });
  }

  update(animateur: IAnimateur): Observable<EntityResponseType> {
    return this.http.put<IAnimateur>(`${this.resourceUrl}/${getAnimateurIdentifier(animateur) as number}`, animateur, {
      observe: 'response',
    });
  }

  partialUpdate(animateur: IAnimateur): Observable<EntityResponseType> {
    return this.http.patch<IAnimateur>(`${this.resourceUrl}/${getAnimateurIdentifier(animateur) as number}`, animateur, {
      observe: 'response',
    });
  }

  regenPass(animateur: IAnimateur): Observable<HttpResponse<string>> {
    const url = this.applicationConfigService.getEndpointFor('api/animateurs-regenerer-passwd', 'ciblagegeo')
    return this.http.post<string>(url, animateur, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IAnimateur>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IAnimateur[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  queryByOng(idOng: number): Observable<EntityArrayResponseType> {
    const url = this.applicationConfigService.getEndpointFor('api/animateurs/par-ong', 'ciblagegeo');
    return this.http.get<IAnimateur[]>(url, { params: { idOng: `${idOng}` }, observe: 'response' });
  }

  queryNonAffectesByOng(idOng: number): Observable<EntityArrayResponseType> {
    const url = this.applicationConfigService.getEndpointFor('api/animateurs-nonaffectes-par-ong', 'ciblagegeo');
    return this.http.get<IAnimateur[]>(url, { params: { idOng: `${idOng}` }, observe: 'response' });
  }

  queryAnimateursEquipeEtNonAffectesByOng(idOng: number, idEquipe: number): Observable<EntityArrayResponseType> {
    const url = this.applicationConfigService.getEndpointFor('api/animateurs-equipe-animateurs-nonaffectes-par-ong', 'ciblagegeo');
    return this.http.get<IAnimateur[]>(url, { params: { idOng: `${idOng}`, idEquipe: `${idEquipe}` }, observe: 'response' });
  }


  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addAnimateurToCollectionIfMissing(
    animateurCollection: IAnimateur[],
    ...animateursToCheck: (IAnimateur | null | undefined)[]
  ): IAnimateur[] {
    const animateurs: IAnimateur[] = animateursToCheck.filter(isPresent);
    if (animateurs.length > 0) {
      const animateurCollectionIdentifiers = animateurCollection.map(animateurItem => getAnimateurIdentifier(animateurItem)!);
      const animateursToAdd = animateurs.filter(animateurItem => {
        const animateurIdentifier = getAnimateurIdentifier(animateurItem);
        if (animateurIdentifier == null || animateurCollectionIdentifiers.includes(animateurIdentifier)) {
          return false;
        }
        animateurCollectionIdentifiers.push(animateurIdentifier);
        return true;
      });
      return [...animateursToAdd, ...animateurCollection];
    }
    return animateurCollection;
  }
}
