import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'app/entities/user/user.service';


@Component({
  templateUrl: './regenpass-user-dialog.component.html',
})
export class RegenPassUserDialogComponent {
  login!: string;
  password!: string;
  isLoading = false;
  nomUser!: string;
  prenomUser!: string;

  constructor(protected userService: UserService, public activeModal: NgbActiveModal) { }

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirm(): void {
    this.isLoading = true;
    this.userService.regenPassUser(this.login).subscribe(
      (res) => {
        this.password = res.body!.result;
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
