import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { DATE_FORMAT } from 'app/config/input.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IAgMpDto, IMenagePreidentifie, getMenagePreidentifieIdentifier } from '../menage-preidentifie.model';
import { IMpiDto } from 'app/entities/collectedonnees/collecte-donnees.model';

export type EntityResponseType = HttpResponse<IMenagePreidentifie>;
export type EntityArrayResponseType = HttpResponse<IMenagePreidentifie[]>;

@Injectable({ providedIn: 'root' })
export class MenagePreidentifieService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/menage-preidentifies', 'ciblagegeo');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) { }

  create(menagePreidentifie: IMenagePreidentifie): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(menagePreidentifie);
    return this.http
      .post<IMenagePreidentifie>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(menagePreidentifie: IMenagePreidentifie): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(menagePreidentifie);
    return this.http
      .put<IMenagePreidentifie>(`${this.resourceUrl}/${getMenagePreidentifieIdentifier(menagePreidentifie) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(menagePreidentifie: IMenagePreidentifie): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(menagePreidentifie);
    return this.http
      .patch<IMenagePreidentifie>(`${this.resourceUrl}/${getMenagePreidentifieIdentifier(menagePreidentifie) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IMenagePreidentifie>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  queryByAg(idAg: number): Observable<HttpResponse<IAgMpDto>> {
    const url = this.resourceUrl + '/par-ag';
    return this.http
      .get<IAgMpDto>(url, { params: { idAg: `${idAg}` }, observe: 'response' })
      .pipe(map((res: HttpResponse<IAgMpDto>) => this.convertDateMpFromServer(res)));
  }

  queryNonAffectesByZd(idZdc: number, loginControleur: string): Observable<HttpResponse<IMpiDto[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/menage-preidentifies-non-affectes-zd', 'ciblagegeo');
    return this.http
      .get<IMpiDto[]>(url, { params: { idZdc: `${idZdc}`,  loginControleur: `${loginControleur}` }, observe: 'response' });
  }

  queryAffectesByEnqueteur(idEnqueteur: number): Observable<HttpResponse<IMpiDto[]>> {
    const url = this.applicationConfigService.getEndpointFor('api/menage-preidentifies-enqueteur', 'ciblagegeo');
    return this.http
      .get<IMpiDto[]>(url, { params: { idEnqueteur: `${idEnqueteur}` }, observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IMenagePreidentifie[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addMenagePreidentifieToCollectionIfMissing(
    menagePreidentifieCollection: IMenagePreidentifie[],
    ...menagePreidentifiesToCheck: (IMenagePreidentifie | null | undefined)[]
  ): IMenagePreidentifie[] {
    const menagePreidentifies: IMenagePreidentifie[] = menagePreidentifiesToCheck.filter(isPresent);
    if (menagePreidentifies.length > 0) {
      const menagePreidentifieCollectionIdentifiers = menagePreidentifieCollection.map(
        menagePreidentifieItem => getMenagePreidentifieIdentifier(menagePreidentifieItem)!
      );
      const menagePreidentifiesToAdd = menagePreidentifies.filter(menagePreidentifieItem => {
        const menagePreidentifieIdentifier = getMenagePreidentifieIdentifier(menagePreidentifieItem);
        if (menagePreidentifieIdentifier == null || menagePreidentifieCollectionIdentifiers.includes(menagePreidentifieIdentifier)) {
          return false;
        }
        menagePreidentifieCollectionIdentifiers.push(menagePreidentifieIdentifier);
        return true;
      });
      return [...menagePreidentifiesToAdd, ...menagePreidentifieCollection];
    }
    return menagePreidentifieCollection;
  }

  protected convertDateFromClient(menagePreidentifie: IMenagePreidentifie): IMenagePreidentifie {
    return Object.assign({}, menagePreidentifie, {
      dateEtablissmtPiece: menagePreidentifie.dateEtablissmtPiece?.isValid()
        ? menagePreidentifie.dateEtablissmtPiece.format(DATE_FORMAT)
        : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.dateEtablissmtPiece = res.body.dateEtablissmtPiece ? dayjs(res.body.dateEtablissmtPiece) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((menagePreidentifie: IMenagePreidentifie) => {
        menagePreidentifie.dateEtablissmtPiece = menagePreidentifie.dateEtablissmtPiece
          ? dayjs(menagePreidentifie.dateEtablissmtPiece)
          : undefined;
      });
    }
    return res;
  }

  protected convertDateMpFromServer(res: HttpResponse<IAgMpDto>): HttpResponse<IAgMpDto> {
    if (res.body) {
      res.body.listeMp!.forEach((menagePreidentifie: IMenagePreidentifie) => {
        menagePreidentifie.dateEtablissmtPiece = menagePreidentifie.dateEtablissmtPiece
          ? dayjs(menagePreidentifie.dateEtablissmtPiece)
          : undefined;
      });
    }
    return res;
  }
}
