import { ITypeDecoupage } from 'app/entities/scorepmt/type-decoupage/type-decoupage.model';

export interface IModelePmt {
  id?: number;
  libelle?: string;
  description?: string | null;
  typeDecoupage?: ITypeDecoupage;
}

export class ModelePmt implements IModelePmt {
  constructor(public id?: number, public libelle?: string, public description?: string | null, public typeDecoupage?: ITypeDecoupage) {}
}

export function getModelePmtIdentifier(modelePmt: IModelePmt): number | undefined {
  return modelePmt.id;
}
